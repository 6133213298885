import styles from "./Galery.module.scss";
import BlocTitle from '../../common/BlocTitle/BlocTitle';
import ImageGallery from 'react-image-gallery';
import { useEffect, useState } from "react";

function Galery() {
   const [images, setImages] = useState([]);
   const [isLoading, setIsloading] = useState(false);

   useEffect(() => {
      async function getImages() {
         setIsloading(true)
         try {
            var response = await fetch(`https://intranet.covenhgroupe.com/galeries`,
               {
                  method: "GET",
                  headers: new Headers({
                     Accept: "application/json"
                  })
               }
            )
            if (response.ok) {
               let images = await response.json();
               setImages(images);
               setIsloading(false)
            } else {
               console.log("response not ok")
               setIsloading(false)
            }
         } catch (error) {
            console.log(error)
         }
      };
      getImages();
   }, []);

   function getImages() {
      let imgs = []
      if (images) {
         images.forEach(img => {
            let thumbnail = img.image.formats && img.image.formats.thumbnail ? img.image.formats.thumbnail.url : img.image.url
            let im = {
               original: "https://intranet.covenhgroupe.com" + img.image.url,
               thumbnail: "https://intranet.covenhgroupe.com" + thumbnail,
               sizes: {
                  width: 150,
                  height: 150,
               }
            }
            imgs.push(im)
         });
      }
      return imgs;
   }

   return (
      <section id="galeries" className={`${styles.galeries} d-flex-column-pcc`}>
         {isLoading ?
            <p>Loading .....</p>
            :
            <div className={`${styles.content} d-flex-column-pcc`}>
               <BlocTitle title={"Galerie"} className="text-black-1" level={"2"} ></BlocTitle>
               <div className={`${styles.img}`}>
                  <ImageGallery items={getImages()} additionalClass={styles.addedClass} thumbnailPosition="left" />
               </div>
            </div>
         }
      </section>
   );
}
export default Galery;
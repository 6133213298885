const datas = [
    {
        "nav": "banner",
        "name": "ACCUEIL"
    },
    {
        "nav": "about",
        "name": "À PROPOS"
    },
    {
        "nav": "services",
        "name": "SERVICES"
    },
    {
        "nav": "teams",
        "name": "ÉQUIPES"
    },
    {
        "nav": "galeries",
        "name": "GALLERIE"
    },
    {
        "nav": "blog",
        "name": "BLOG"
    },
    {
        "nav": "contact",
        "name": "CONTACT"
    },
]
export default datas;
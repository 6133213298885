
function BlocTitle({ title, level, className }) {
    return (
        <>
            {level === "1" && <h1 className={` ${className ? className : ''}`}> {title}</ h1>}
            {level === "2" && <h2 className={` ${className ? className : ''}`}> {title}</ h2>}
            {level === "3" && <h3 className={` ${className ? className : ''}`}> {title}</ h3>}
            {level === "5" && <h5 className={` ${className ? className : ''}`}> {title}</ h5>}
        </>
    );
}

export default BlocTitle;
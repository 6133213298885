import styles from "./Team.module.scss";
import BlocTitle from "../../common/BlocTitle/BlocTitle";
import data from "../../../data/Team";
import Card from "../../common/Card/Card";

function Team() {
   return (
      <section id="teams" className={`${styles.teams} d-flex-column-aic`}>
         <div className={`calc`}></div>
         <BlocTitle title="Notre équipes" level={"2"} className={"text-white-1"} />
         {< div className={`container ${styles.content}`}>
            {data && data[0] && data.map((d) => (
               <Card
                  key={d.id}
                  title={`${d.prenom} ${d.nom}`}
                  subtitle={d.poste}
                  img={d.img}
                  content={d.presentation}
                  maxContenLenght={"300"}
                  imgClassName={"rounded_img"}
                  socials={d.socials}
                  iconClassName={" icon-primary"}
                  isLongContent={false}
               />
            ))}
         </div>}

      </section >
   );
}
export default Team;
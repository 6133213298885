const data = [
    {
        "id": 1,
        "auteur": "Hadiatou BALDE",
        "texte": "Service fiable et sûr, produit de qualité, satisfaction garantie 👌👌",
        "created_at": "2020-05-17T03:22:48.357Z",
        "updated_at": "2020-06-07T01:37:43.551Z",
        "created_by": null,
        "updated_by": null
    },
    {
        "id": 2,
        "auteur": "Issatou Bella DIALLO",
        "texte": "Coven H groupe regroupe vraiment des personnes de valeurs et très rigoureuses.\n",
        "created_at": "2020-05-17T03:23:37.402Z",
        "updated_at": "2020-06-07T01:38:23.131Z",
        "created_by": null,
        "updated_by": null
    },
    {
        "id": 3,
        "auteur": "Mariame SOW",
        "texte": "Huile de palme délicieuse et de qualité supérieure. Nous avons commandé une boîte et avons reçu un excellent service!\nMeilleure huile de palme à Montréal!\n👌🏾 #covenH",
        "created_at": "2020-05-17T03:23:53.026Z",
        "updated_at": "2020-06-07T01:39:14.982Z",
        "created_by": null,
        "updated_by": null
    },
    {
        "id": 5,
        "auteur": "Alpha Mahmoud BARRY",
        "texte": "Je recommande fortement CovenH Groupe! Sérieux, professionnalisme, leadership à tous les niveaux... La meilleure huile de palme au Canada en plus des autres services offerts par le groupe.",
        "created_at": "2021-01-17T23:43:09.597Z",
        "updated_at": "2021-01-17T23:43:45.713Z",
        "created_by": null,
        "updated_by": null
    },
    {
        "id": 7,
        "auteur": "Adrien BRICAUD (Propriétaire de l'Épicurien)",
        "texte": "Nous sommes fiers que le premier chèque de Coven H vienne de l'Épicurien et il y en aura j'espère beaucoup d'autres dans le futur. Nous continuerons toujours à faire appel à Coven H!\nBonne année et beaucoup de succès mignan!",
        "created_at": "2021-01-17T23:47:06.372Z",
        "updated_at": "2021-01-17T23:47:06.372Z",
        "created_by": null,
        "updated_by": null
    },
    {
        "id": 6,
        "auteur": "Benjamin BRICAUD (Co-Propriétaire de l'Épicurien)",
        "texte": "Félicitations Hamidou Bah !\nJ'ai pu voir naître Coven H et je suis impressionné (mais pas du tout surpris) de son succès et sa croissance rapide 🙂\nUn succès mérité! J'en profite pour te souhaiter de joyeuses fêtes et une bonne année 2021 qui arrive à grands pas 🙂",
        "created_at": "2021-01-17T23:45:43.082Z",
        "updated_at": "2021-01-17T23:47:28.843Z",
        "created_by": null,
        "updated_by": null
    }
]
export default data;

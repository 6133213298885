import { useState } from 'react';
import logo from '../../assets/images/covenh.png'
import data from '../../data/Header';
import styles from './Header.module.scss';
import HeaderMenu from './HeaderMenu/HeaderMenu';

function Header() {
    const [showMenu, setShowMenu] = useState(false)

    return (
        <header className={`${styles.header}`}>
            <div className={`d-flex flex-row flex-pcc${styles.logo} p-10`}>
                <a href='/'><img src={logo} alt="logo" /></a>
            </div>
            <div className={`${styles.headerInfo}`}>
                <ul className={`d-flex flex-row flex-pcc ${styles.headerList}`}>
                    {data && data.map(h =>
                        <li key={h.name} className={`mr-15`}>
                            <a href={`/#${h.nav}`}>{h.name}</a>
                        </li>
                    )}
                </ul>
                <div className={`d-flex flex-row flex-pcc ${styles.contact}`}>
                    <div className={`${styles.item} d-flex flex-row flex-pcc`}>
                        <div className={`${styles.itemIcon}`}>
                            <i className="fa-solid fa-phone icon-primary mr-5"></i>
                            <span className='mr-5'>:</span>
                        </div>
                        <span className='mr-10'> (514)4305101</span>
                    </div>
                    <div className={`${styles.item}`}>
                        <div className={`${styles.itemIcon}`}>

                            <i className="fa-solid fa-envelope icon-primary mr-5"></i>
                            <span className='mr-5'>:</span>
                        </div>
                        <span>covenhgroupe@gmail.com</span>
                    </div>
                </div>
            </div>
            <i onClick={() => setShowMenu(true)} className={`fa-solid fa-bars ${styles.headerXs}`}></i>
            {showMenu &&
                <>
                    <div onClick={() => setShowMenu(false)} className='calc-fixed '></div>
                    <HeaderMenu data={data} />
                </>

            }
        </header>
    );
}
export default Header;
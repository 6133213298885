const data =
{
    title: "Qui Sommes\n -Nous ? Que Faisons\n -Nous?",
    description: `Coven H Groupe, dans son volet consultation, offre des services en orientation stratégique d'entreprises. 
        Nous aidons les entreprises à mettre en place des processus organisationnels et logistiques afin de les accompagner 
        dans leurs croissances. Nous nous concentrons sur les domaines ci\n -dessous`,
    activities: [
        {
            title: "Consultation en gestion stratégique",
            description: 'La planification stratégique au sein des entreprises a évolué au fil des années en ce sens qu’elle devient transversale et quasiment indispensable pour une organisation optimale. Une bonne planification permet à toute entreprise de déterminer avec précisions et réalisme où l’entrepreneur veut amener son entreprise à court, moyen ou à long termes. Une fois la vision acquise, il faut se donner les moyens techniques, technologiques, humains et financiers pour y arriver. Une bonne stratégie d’entreprise est la boussole de l’entreprise et doit prendre en compte trois facteurs essentiels : la vision, la coordination et la méthode. Coven H permettra ainsi à son client de réaliser avec rigueur et objectivité ce facteur décisionnel de l’entreprise. Entre autres nous pourrons établir les points ci\n -dessous: \n - Diagnostic interne et externe de la situation de l\'entreprise \n - Planification stratégique: stratégie et orientation à adopter \n - Rédaction de documents internes pour l’entreprise : (Memos, modes d’emploi, manuels, contenus web etc.) \n - Mise en place des régimes gouvernementaux (RVER, CNESST, etc.) \n - Recherche de financements (prêts, dons, subventions, crédits\n -baux, etc.) \n - Accompagnement pour une mise en place d’un régime de couverture santé',
        }
        ,
        {
            title: "Consultation en Gestion des opérations et de la production G.O.P",
            description: "La GOP a pour principale fonction au sein d’une entreprise de mettre en exergue une planification et un contrôle de toutes les ressources prises en compte dans la chaine logistique afin d’impacter sur la productivité ainsi que sur la qualité des produits et des services offerts par l’entreprise. Coven H intervient sous différents angles pour améliorer la position concurrentielle de l’entreprise : \n - Évaluation des processus de l’entreprise \n - Optimisation de la chaîne logistique (approvisionnement, production, distribution et service après\n -vente SAV) \n - Norme GS1 : normalisation des méthodes de codages dans la chaîne logistique avec le système mondial GS1 \n - Mise en place des normes en matière de chaine d’approvisionnement électronique (codes à barres et images des produits avec ECCNET) \n - Mise en place du tableau des des valeurs nutritives \n - Conception, réalisation et impression d'étiquettes \n - Référencement auprès des distributeurs au Québec"
        },
        {
            title: "Consultation en Gestion des ressources humaines",
            description: "De nos jours, la plupart des entrepreneurs sont parfois confrontés à un problème sérieux de gestion des ressources humaines. Parfois dû au fait du quasi plein emploi, certains employés ne restent pas longtemps au même endroit. Ce qui met le chef d’entreprise dans une situation de stress notamment pour gérer les différents aspects liés à la gestion de l’Homme. Il faut donc prévoir et anticiper. Coven H aide entre autres sur les différents points ci\n -après (Recrutement, Formation, Évaluation, Planification (emploi de temps, vacances.. ), Motivation et Rendement du personnel."
        },
        {
            title: "Consultation en Gestion du système d’information",
            description: "Le monde technologique évolue à vitesse exponentielle. Nous assistons tous les jours à la mise sur le marché d’outils performants visant à gérer, sécuriser et fluidifier l’information. L’entrepreneur n’a souvent pas le temps de mettre à jour tous ses outils. Coven H joue le rôle d’opérateur de veille stratégique pour votre entreprise en de nombreux points : \n - Diagnostic et optimisation du système d’information de l’entreprise. \n - Analyse et amélioration des canaux utilisés \n - L’optimisation des avantages procurés par les nouvelles technologies. \n - La mise en place des solutions informatiques liées notamment à la sécurisation des données."
        },
        {
            title: "Consultation en communication digitale",
            description: "Conception de sites webs \n - Marketing Digitale \n - Référencement sur les sites e-commerces"
        }
    ]
}
export default data;

import styles from "./Blog.module.scss";
import BlocTitle from "../../common/BlocTitle/BlocTitle";
import data from "../../../data/Blog";
import Card from "../../common/Card/Card";

function Blog({ page }) {
   return (

      <section id="blog" className={`${styles.articles} d-flex-column-pcc`}>
         {page == "blog" ?
            <>
               <BlocTitle title="Blog" level={"2"} className={"text-black-1 mt-100"} />
               <div className={`container ${styles.content}`}>
                  {data && data[0] && data.map((d) => (
                     <Card
                        key={d.id}
                        title={`${d.title}`}
                        img={d.img}
                        content={d.content}
                        isLongContent={false}
                        maxContenLenght={"1000"}
                        link={"/blog"}
                     />
                  ))}
               </div>
            </>
            :
            <>
               <BlocTitle title="Blog" level={"2"} className={"text-black-1"} />
               <div className={`container ${styles.content}`}>
                  {data && data[0] && data.slice(0, 3).map((d) => (
                     <Card
                        key={d.id}
                        title={`${d.title}`}
                        img={d.img}
                        content={d.content}
                        maxContenLenght={"300"}
                        isLongContent={false}
                        link={"/blog"}
                     />
                  ))}
               </div>
               <div className="d-flex-column-pcc container mt-30">
                  <a className="btn btn-reverse-primary" href="/blog" >Aller sur le blog</a>
               </div>
            </>

         }
      </section>

   );
}
export default Blog;
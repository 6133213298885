
import styles from "./Brand.module.scss";
import BlocTitle from "../../common/BlocTitle/BlocTitle";
import data from "../../../data/Brand";
import AdSlider from "../../common/AdSlider/AdSlider";


function Brand() {
   return (
      <section className={`${styles.brands} d-flex-column-aic`}>
         <div className={`${styles.content} d-flex-column-pcc`}>
            <BlocTitle title={"Nos partenaires"} className="text-black-1" level={"2"} ></BlocTitle>
            <AdSlider total={data ? data.length : 0} sliderData={data} isImageSlider={true} ></AdSlider>
         </div>

      </section>
   );
}
export default Brand;
import Header from './components/header/Header';
import Footer from './components/footer/Footer';
import styles from './App.module.scss';
import { Outlet } from 'react-router-dom';
function App() {
  return (
    <>
      <div className={`d-flex flex-column ${styles.appContainer}`}>
        <Header />
        <Outlet />
        <Footer />
      </div>

    </>
  );
}

export default App;

import styles from './About.module.scss';
import data from '../../../data/About';
import BlocTitle from '../../common/BlocTitle/BlocTitle';
import Card from "../../common/Card/Card";

function About({ page }) {

   return (
      <section id="about" className={`d-flex-column-pcc container ${styles.about}`}>
         {page == "about" ?
            <>
               <div className={`${styles.header} d-flex-column-pcc mt-100`}>
                  <BlocTitle title={data.title} className="text-black-1" level={"2"} ></BlocTitle>
                  <p className={`text-lead text-align-center px-30 pb-30`}>{data.description}</p>
               </div>
               <div className={`${styles.content}`}>
                  {data && data.activities && data.activities.map((d) => (
                     <Card
                        key={d.title}
                        title={`${d.title}`}
                        content={d.description}
                        isLongContent={true}
                     />
                  ))}
               </div>
            </>
            :
            <>
               <div className={`${styles.header} d-flex-column-pcc`}>
                  <BlocTitle title={data.title} className="text-black-1" level={"2"} ></BlocTitle>
                  <p className={`text-lead text-align-center px-30 pb-30`}>{data.description}</p>
               </div>
               <div className={`${styles.content}`}>
                  {data && data.activities && data.activities.map((d) => (
                     <div key={d.title} className={`${styles.contentItem}`}>
                        <div className={`${styles.icon} `}>
                           <i className="fa-solid fa-globe icon-primary"></i>
                        </div>
                        <div className={`${styles.textContent}`} >
                           <div className={`${styles.textContainer} `}>
                              <h3 className={`text-black-1`}>{d.title}</h3>
                              <p className={`${styles.text}`}>{d.description && d.description.length > 300 ? `${d.description.slice(0, 300)}...` : d.description}</p>
                           </div>
                           {d.description && d.description.length > 300 &&
                              <a className='btn btn-primary uppercase' href='/about' >Voir plus</a>
                           }
                        </div>


                     </div>
                  ))}
               </div>
            </>
         }

      </section >
   );
}
export default About;
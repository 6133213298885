
import styles from "./Testimonial.module.scss";
import BlocTitle from '../../common/BlocTitle/BlocTitle';
import Slider from "../../common/Slider/Slider";
import data from "../../../data/Testimonial";
function Testimonial() {
   return (
      <section className={`${styles.testimonials} d-flex-column-pcc`}>
         <div className={`calc`}></div>
         <div className={`${styles.content} d-flex-column-pcc`}>
            <BlocTitle title={"Ce que nos clients disent de nous"} className="text-white-1" level={"2"} ></BlocTitle>
            <Slider total={data ? data.length : 0} sliderData={data} ></Slider>
         </div>
      </section>
   );
}
export default Testimonial;
import Button from "../Button/Button";
import styles from "./Card.module.scss";
import BlocTitle from "../BlocTitle/BlocTitle";
import SocialBlock from "../SocialBlock/SocialBlock";


function Card({ title, subtitle, img, content, maxContenLenght, imgClassName, socials, iconClassName, link }) {

    return (
        <div className={` d-flex-column-icc p-20 cardItem ${styles.card}`}>
            {img &&
                <div className={`flex-fill d-flex-column-pcc container cardImg `}>
                    <img
                        className={` flex-fill ${imgClassName ? imgClassName : ''}`}
                        src={`${img}`}
                        alt=""
                    />
                </div>
            }
            <div className={`cardText`}>
                {subtitle ?
                    <div className={` d-flex-column-pcc`}>
                        <BlocTitle title={title} level={"3"} className={"text-black-1"} />
                        <BlocTitle title={subtitle} level={"5"} className={"text-gray-1"} />
                    </div>
                    :
                    <BlocTitle title={title} level={"3"} className={"text-black-1"} />

                }
                <p className="p-20 text-gray-1">{content && content.length > maxContenLenght ? `${content.slice(0, 200)}...` : content}</p>
            </div>
            {content && content.length > maxContenLenght && <Button text="Voir plus" link={link} />}
            {socials &&
                <SocialBlock socials={socials} iconClassname={iconClassName} />
            }
        </div >
    );

}
export default Card;
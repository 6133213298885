import { createBrowserRouter } from 'react-router-dom';
import App from './App';
import Homepage from './pages/HomePage/HomePage';
import BlogPage from './pages/BlogPage/BlogPage';
import AboutPage from './pages/AboutPage/AboutPage';

export const router = createBrowserRouter([
    {
        path: '/',
        element: <App />,
        children: [
            {
                path: '/',
                element: <Homepage />,
            },
            {
                path: '/about',
                element: <AboutPage />,
            },
            {
                path: '/blog',
                element: <BlogPage />,
            },
        ],
    },
]);
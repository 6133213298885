import styles from './AdSlider.module.scss';

function AdSlider({ total, sliderData, isImageSlider }) {

    return (
        <div className={` d-flex-column-pcc ${styles.adSlider}`}>
            <div className={` d-flex-row-pcc ${styles.adSliderTrack}`}>
                <div className={`${styles.slideImg}`}>
                    <img src={sliderData[0].img} alt="" />
                </div>
                {sliderData && sliderData.map((slide, index) =>
                    <div key={index} className={`${styles.slideImg}`}>
                        <img src={slide.img} alt="" />
                    </div>
                )}
            </div >
        </div>
    );
};

export default AdSlider
import styles from './Footer.module.scss';
function Footer() {
    return (
        <footer className={`d-flex flex-row flex-aic p-30 ${styles.footer}`}>
            <span className='flex-fill pl-30'>Copyright © 2020 All Rights Reserved : Covenh groupe Inc.</span>
            <div>
                <i className="fa-brands fa-facebook mr-10"></i>
                <i className="fa-brands fa-twitter mr-10"></i>
                <i className="fa-brands fa-instagram mr-10"></i>
                <i className="fa-brands fa-linkedin mr-30"></i>
                <i className="fa-solid fa-arrow-up icon-primary"></i>
            </div>
        </footer>
    );
}
export default Footer;
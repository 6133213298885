import styles from "./Contact.module.scss";
import BlocTitle from "../../common/BlocTitle/BlocTitle";
import Button from "../../common/Button/Button";


function Contact() {
   return (
      <section id="contact" className={`${styles.contact} d-flex-column-pcc`}>
         <div className={`calc`}></div>
         <div className={`${styles.content} d-flex-column-pcc container`}>
            <BlocTitle title={"Nous contacter"} className="text-white-1" level={"2"} ></BlocTitle>
            <div className={`${styles.item} container `}>
               <div className={`${styles.address} `}>
                  <div>
                     <h3>Adresse:</h3>
                     <p>9402 Rue Hochelaga H1L 2P6, Tétraultville, Montréa</p>
                  </div>
                  <div>
                     <h3>Ville:</h3>
                     <p>Québec, Canada</p>
                  </div>
                  <div>
                     <h3>Horaires:</h3>
                     <p>De 9h:00 À 17h:00 (Heures de Montréal)</p>
                  </div>
               </div>
               <form className={` ${styles.form}  d-flex-column `} action="">
                  <div className={`${styles.formItem}`}>
                     <input type="text" placeholder="Votre nom*" className="mr-10" />
                     <input type="text" placeholder="Votre email*" />
                  </div>
                  <div className={`${styles.formItem} `}>
                     <input type="text" placeholder="Objet" />
                  </div>
                  <div className={`${styles.formItem} `}>
                     <textarea name="comments" id="comments" rows="6" placeholder="votre message..."></textarea>
                  </div>
                  <div className={`${styles.formBtn}`}>
                     <Button text="Envoyer" />
                  </div>
               </form>
            </div>
         </div >
      </section >
   );
}
export default Contact;
import styles from "./SocialBlock.module.scss";

function SocialBlock({ socials, iconClassname }) {
    return (
        <div className={`${styles.socials}  d-flex-row-pcc`}>
            {socials && socials[0] && socials.map((s) => (
                <a key={s.name} href={s.url} target="_blank">
                    <i className={`fa-brands fa-${s.name} mr-10 ${iconClassname ? iconClassname : ''}`}></i>
                </a>
            ))}
        </div>
    );

}
export default SocialBlock;
import Banner from './Banner/Banner';
import About from './About/About';
import Info from './Info/Info';
import Service from './Service/Service';
import Team from './Team/Team';
import Blog from './Blog/Blog';
import Testimonial from './Testimonial/Testimonial';
import Brand from './Brand/Brand';
import Galery from './Galery/Galery';
import Contact from './Contact/Contact';

function Content() {
    return (
        <div className="flex-fill">
            <Banner />
            <About />
            <Info />
            <Service />
            <Team />
            <Blog />
            <Testimonial />
            <Brand />
            <Galery />
            <Contact />
        </div>
    );
}
export default Content;
import styles from './Button.module.scss';


function Button({ text, className, link }) {
    return (
        <a
            className={`btn ${styles.button} ${className ? className : 'btn-primary'} uppercase mr-15`}
            href={link ? link : '#'}
        >
            {text}
        </a>
    );
}

export default Button;
import styles from "./Info.module.scss";
import BlocTitle from '../../common/BlocTitle/BlocTitle';

function Info() {
   return (
      <section className={`${styles.info}  d-flex-column-pcc`}>
         <div className={`calc`}></div>
         <div className={`${styles.infoContent}`}>
            <BlocTitle title={"Notre expertise à votre service"} className="text-white-1" level={"2"} ></BlocTitle>
            <div className={`text-white-1 ${styles.infoContentText}`}>
               <p>Coven H se rapproche de vous, évalue vos besoins, vous propose des outils et des solutions adaptés pour leurs mises en œuvre.</p>
               <p>Coven H vous fournira toutes les solutions, techniques, technologiques, humaines et financières afin de gérer votre entreprise.</p>
               <p>Coven H se positionne comme un partenaire à très long terme auprès de ses partenaires pour les épauler et les orienter.</p>
            </div>
            <div className="p-30">
               <a href="#contact" className='btn btn-primary uppercase mt-10'> contacter-nous</a>
            </div>
         </div>
      </section>
   );
}
export default Info;
import styles from "./Service.module.scss";
import BlocTitle from "../../common/BlocTitle/BlocTitle";
import data from "../../../data/Service";
import Card from "../../common/Card/Card";

function Service() {

   return (
      <section id="services" className={`${styles.services} d-flex-column-pcc`}>
         <BlocTitle title="Nos services" level={"2"} className={"text-black-1"} />
         <div className={`container ${styles.content}`}>
            {data && data[0] && data.map((d) => (
               <Card
                  key={d.id}
                  title={`${d.title}`}
                  img={d.img}
                  content={d.description}
                  maxContenLenght={"200"}
                  isLongContent={false}
               />
            ))}
         </div>

      </section>
   );
}
export default Service;
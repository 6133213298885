import styles from './Banner.module.scss';
import data from '../../../data/Banner';
import BlocTitle from '../../common/BlocTitle/BlocTitle';


function Banner() {
   return (
      <>
         <section id="banner" className={`d-flex-column-pcc ${styles.banner}`}>
            <div className={`calc`}></div>
            <div className={`d-flex-column-pcc ${styles.bannerText} text-gray-3`}>
               <BlocTitle title={data.title} level="1"></BlocTitle>
               <p>{data.text}</p>
               <a href="#contact" className='btn btn-primary uppercase mt-10'> contacter-nous</a>
            </div>
         </section>
         <section className={`${styles.nav}`}>
            <h2>Consultation en gestion d’entreprises</h2>
            <h2>Import & Export</h2>
            <h2>Prestation de services</h2>
         </section>
      </>
   );
}
export default Banner;
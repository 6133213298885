import { useState } from "react";
import styles from './Slider.module.scss';

function Slider({ total, sliderData }) {
    const [current, setCurrent] = useState(0);
    const length = total;

    function nextSlide() {
        console.log("ici")
        setCurrent(current === length - 1 ? 0 : current + 1);
    };

    function prevSlide() {
        setCurrent(current === 0 ? length - 1 : current - 1);
    };

    function changeSlide(e) {
        setCurrent(Number(e.target.value));
    }

    return (
        <div className={` d-flex-column-pcc ${styles.slider}`}>
            {sliderData && sliderData.map((slide, index) =>
                <div key={index} className={`${styles.slideItem}`}>
                    {index === current &&
                        <>
                            <p className={`${styles.content}`}>{slide.texte}</p>
                            <p className={`${styles.title} uppercase`}>{slide.auteur}</p>
                        </>
                    }
                </div>
            )}
            <div className={`d-flex-row-pcc ${styles.icons}`}>
                <i className="fa-solid fa-chevron-left  p-10 icon-primary" onClick={prevSlide}></i>
                <i className="fa-solid fa-chevron-right p-10 icon-primary" onClick={nextSlide}></i>
            </div>
            <div className={`d-flex-row-pcc ${styles.radio}`}>
                {sliderData &&
                    sliderData.map((slide, index) =>
                        <input
                            key={index}
                            type="radio"
                            value={index}
                            checked={index === current}
                            onChange={changeSlide}
                        />
                    )}
            </div>

        </div >
    );
};

export default Slider
import styles from './HeaderMenu.module.scss';

function HeaderMenu({ data }) {
    return (
        <ul className={`${styles.MenuContainer} card p-20`}>
            {data && data.map(h =>
                <li key={h.name} className={`mr-15`}>
                    <a href={`#${h.nav}`}>{h.name}</a>
                </li>
            )}
        </ul>
    );
}

export default HeaderMenu;